import React, { createContext, useContext, useEffect, useState, useRef, useMemo } from 'react';
import 'react-notifications/lib/notifications.css';
import { NotificationManager } from 'react-notifications';
import Pusher from 'pusher-js';
import { useAuthContext } from '../hooks';
import { Howl } from 'howler';
import { NotificationSound } from 'src/assets/audio';

// Create the context
const PusherContext = createContext();

// Create a provider component
export const PusherProvider = ({ token, children }) => {
  const { user } = useAuthContext();
  const [onlineMembers, setOnlineMembers] = useState([]);
  const activePresenceRef = useRef(null);

  useEffect(() => {
    if (!token) return; // Exit early if token is not provided

    const pusher = new Pusher('f36706c4316bca528c78', {
      cluster: 'ap2',
      authEndpoint: process.env.REACT_APP_PUSHERURL,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });

    const activeStatusChannel = pusher.subscribe('presence-activeStatus');
    activePresenceRef.current = activeStatusChannel;

    activeStatusChannel.bind('pusher:subscription_succeeded', (e) => {
      const result = Object.entries(e.members).map(([id, { name }]) => ({
        id: Number(id),
        name,
      }));
      setOnlineMembers(result);
    });

    activeStatusChannel.bind('pusher:member_added', (member) => {
      const newMember = { id: member?.id, name: member?.info?.name };
      setOnlineMembers((prev) => [...prev, newMember]);
    });
    // eslint-disable-next-line
    return () => {
      activeStatusChannel.unbind_all();
      activeStatusChannel.unsubscribe();
      pusher.disconnect();
    };
  }, [token]);

  const sound = new Howl({
    src: [NotificationSound],
  });

  const playNotificationSound = () => {
    sound.play();
  };

  useEffect(() => {
    if (!user || !token) return; // Exit early if user or token is not provided

    const pusher = new Pusher('f36706c4316bca528c78', {
      cluster: 'ap2',
      authEndpoint: process.env.REACT_APP_PUSHERURL,
      auth: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    });

    const my_channel = pusher.subscribe(`private-chatify.${user?.id}`);

    my_channel.bind('messaging', (data) => {
      console.log(data);
      playNotificationSound();
      NotificationManager.success(`${data?.contact?.from_user?.name} sent you a message`);
    });
    // eslint-disable-next-line
    return () => {
      my_channel.unbind('messaging');
      pusher.unsubscribe(`private-chatify.${user?.id}`);
    };
    // eslint-disable-next-line
  }, [user, token]);

  activePresenceRef?.current?.bind('pusher:member_removed', (member) => {
    const new_arr = onlineMembers.filter((item) => item?.id !== Number(member?.id));
    setOnlineMembers(new_arr);
  });

  const contextValue = useMemo(() => ({ onlineMembers }), [onlineMembers]);

  return <PusherContext.Provider value={contextValue}>{children}</PusherContext.Provider>;
};

// Custom hook to access online members
export const useOnlineMembers = () => {
  return useContext(PusherContext);
};
