import { useState, useCallback, useEffect } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Drawer from '@mui/material/Drawer';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { useTheme, useColorScheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { paths } from 'src/routes/paths';
import { useRouter, usePathname } from 'src/routes/hooks';

import { _mock } from 'src/_mock';
import { varAlpha } from 'src/theme/styles';

import { Label } from 'src/components/label';
import { Iconify } from 'src/components/iconify';
import { Scrollbar } from 'src/components/scrollbar';
import { AnimateAvatar } from 'src/components/animate';

import { useAuthContext, useMockedUser } from 'src/auth/hooks';

import { UpgradeBlock } from './nav-upgrade';
import { AccountButton } from './account-button';
import { SignOutButton } from './sign-out-button';
import { Button, SvgIcon } from '@mui/material';
import { BaseOption } from 'src/components/settings/drawer/base-option';
import { RouterLink } from 'src/routes/components';
import { useSettingsContext } from 'src/components/settings';
import { api } from 'src/Api';
import axios from 'axios';
import InfoCard from 'src/sections/overview/landing/Info-Card';
import { useNavigate, useLocation } from 'react-router-dom'; // Importing the useNavigate hook

// ----------------------------------------------------------------------

export function SideMenuDrawer({ data = [], sx, ...other }) {
  const theme = useTheme();

  const router = useRouter();
  const navigate = useNavigate();
  const pathname = usePathname();
  const location = useLocation();

  const { user } = useAuthContext();
  // const { user } = useMockedUser();
  const settings = useSettingsContext();
  const [open, setOpen] = useState(false);
  const { mode, setMode } = useColorScheme();
  const handleOpenDrawer = useCallback(() => {
    setOpen(true);
  }, []);

  const [categories, setcategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState(null);

  const currentSlug = location.pathname.split('/')[2]; // Assuming the URL format is '/category/{slug}'

  const handleCloseDrawer = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickItem = useCallback(
    (path) => {
      handleCloseDrawer();
      router.push(path);
    },
    [handleCloseDrawer, router]
  );

  useEffect(() => {
    Get_Categories();
  }, []);

  const isActive = '';

  function Get_Categories() {
    console.log('Fetching categories...');

    axios
      .get(api.get_categories)
      .then((response) => {
        console.log(response?.data?.data);

        // Assuming the API response has the categories in `response.data.categories`
        setcategories(response?.data?.data);
      })
      .catch((error) => {
        console.error('Error fetching categories:', error);
      });
  }

  const renderAvatar = (
    <AnimateAvatar
      width={96}
      slotProps={{
        avatar: { src: user?.avatar, alt: user?.name },
        overlay: {
          border: 2,
          spacing: 3,
          color: `linear-gradient(135deg, ${varAlpha(theme.vars.palette.primary.mainChannel, 0)} 25%, ${theme.vars.palette.primary.main} 100%)`,
        },
      }}
    >
      {user?.name?.charAt(0).toUpperCase()}
    </AnimateAvatar>
  );

  return (
    <>
      {/* <AccountButton
        onClick={handleOpenDrawer}
        photoURL={user?.avatar}
        displayName={user?.name}
        sx={sx}
        {...other}
      /> */}

      <IconButton
        onClick={handleOpenDrawer}
        sx={{
          // position: 'absolute',
          // top: '50%',
          // left: 10,

          // transform: 'translateY(-50%)', // Center the icon vertically
          //  backgroundColor: theme.palette.background.paper,
          padding: 0.5,
          borderRadius: '50%',
          // boxShadow: 1,
        }}
      >
        <SvgIcon>
          <path
            opacity="0.32"
            d="M15.7798 4.5H5.2202C4.27169 4.5 3.5 5.06057 3.5 5.75042C3.5 6.43943 4.27169 7 5.2202 7H15.7798C16.7283 7 17.5 6.43943 17.5 5.75042C17.5 5.06054 16.7283 4.5 15.7798 4.5Z"
            fill={theme.palette.primary.light} // Use primary light for the first line
          />
          <path
            d="M18.7798 10.75H8.2202C7.27169 10.75 6.5 11.3106 6.5 12.0004C6.5 12.6894 7.27169 13.25 8.2202 13.25H18.7798C19.7283 13.25 20.5 12.6894 20.5 12.0004C20.5 11.3105 19.7283 10.75 18.7798 10.75Z"
            fill={theme.palette.primary.main} // Use primary for the second line
          />
          <path
            d="M15.7798 17H5.2202C4.27169 17 3.5 17.5606 3.5 18.2504C3.5 18.9394 4.27169 19.5 5.2202 19.5H15.7798C16.7283 19.5 17.5 18.9394 17.5 18.2504C17.5 17.5606 16.7283 17 15.7798 17Z"
            fill={theme.palette.primary.main} // Use primary for the third line
          />
        </SvgIcon>
      </IconButton>

      <Drawer
        open={open}
        onClose={handleCloseDrawer}
        anchor="left"
        slotProps={{ backdrop: { invisible: true } }}
        PaperProps={{ sx: { width: 320 } }}
      >
        <IconButton
          onClick={handleCloseDrawer}
          sx={{ top: 12, left: 12, zIndex: 9, position: 'absolute' }}
        >
          <Iconify icon="mingcute:close-line" />
        </IconButton>

        <Scrollbar>
          <Stack sx={{ pt: 8, pb: 8 }}>
            {(() => {
              if (user?.role?.id === 1) {
                // Render for role id === 1
                return (
                  <>
                    <Stack
                      key="consultants"
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        ml: 2,
                        padding: theme.spacing(2),
                        borderRadius: 1.5,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        cursor: 'pointer',
                        backgroundColor:
                          currentSlug === 'consultants'
                            ? theme.palette.primary.main
                            : 'transparent',
                        '&:hover': {
                          backgroundColor:
                            currentSlug === 'consultants'
                              ? theme.palette.primary.main
                              : theme.palette.action.hover,
                        },
                      }}
                      onClick={() => {
                        navigate(`/consultants`);
                        setOpen(false);
                      }}
                    >
                      <Iconify
                        icon="raphael:users"
                        sx={{
                          color:
                            currentSlug === 'consultants' ? 'white' : theme.palette.primary.main,
                          mr: 0.5,
                        }}
                        width={24}
                      />
                      <Typography variant="body1" fontWeight="bold">
                        Consultants
                      </Typography>
                    </Stack>
                    <Stack
                      key="consultations"
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        ml: 2,
                        padding: theme.spacing(2),
                        borderRadius: 1.5,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        cursor: 'pointer',
                        backgroundColor:
                          currentSlug === 'consultations'
                            ? theme.palette.primary.main
                            : 'transparent',
                        '&:hover': {
                          backgroundColor:
                            currentSlug === 'consultations'
                              ? theme.palette.primary.main
                              : theme.palette.action.hover,
                        },
                      }}
                      onClick={() => {
                        navigate(`/consultations`);
                        setOpen(false);
                      }}
                    >
                      <Iconify
                        icon="carbon:receipt"
                        sx={{
                          color:
                            currentSlug === 'consultations' ? 'white' : theme.palette.primary.main,
                          mr: 0.5,
                        }}
                        width={24}
                      />
                      <Typography variant="body1" fontWeight="bold">
                        Consultations
                      </Typography>
                    </Stack>
                    {/* <Stack
                      key="chat"
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        ml: 2,
                        padding: theme.spacing(2),
                        borderRadius: 1.5,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        cursor: 'pointer',
                        backgroundColor:
                          currentSlug === 'specialRole4'
                            ? theme.palette.secondary.main
                            : 'transparent',
                        '&:hover': {
                          backgroundColor:
                            currentSlug === 'specialRole4'
                              ? theme.palette.secondary.main
                              : theme.palette.action.hover,
                        },
                      }}
                      onClick={() => {
                        navigate(`/chat`);
                        setOpen(false);
                      }}
                    >
                      <Iconify
                        icon="mdi:chat"
                        sx={{
                          color:
                            currentSlug === 'specialRole4' ? 'white' : theme.palette.primary.main,
                          mr: 0.5,
                        }}
                        width={24}
                      />
                      <Typography variant="body1" fontWeight="bold">
                        Chat
                      </Typography>
                    </Stack> */}
                    <Stack
                      key="members"
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        ml: 2,
                        padding: theme.spacing(2),
                        borderRadius: 1.5,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        cursor: 'pointer',
                        backgroundColor:
                          currentSlug === 'members' ? theme.palette.primary.main : 'transparent',
                        '&:hover': {
                          backgroundColor:
                            currentSlug === 'members'
                              ? theme.palette.primary.main
                              : theme.palette.action.hover,
                        },
                      }}
                      onClick={() => {
                        navigate(`/members`);
                        setOpen(false);
                      }}
                    >
                      <Iconify
                        icon="stash:user-group"
                        sx={{
                          color: currentSlug === 'members' ? 'white' : theme.palette.primary.main,
                          mr: 0.5,
                        }}
                        width={24}
                      />
                      <Typography variant="body1" fontWeight="bold">
                        Members
                      </Typography>
                    </Stack>
                  </>
                );
              }

              if (user?.role?.id === 4) {
                // Render for role id === 4
                return (
                  <>
                    <Stack
                      key="chat"
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        ml: 2,
                        padding: theme.spacing(2),
                        borderRadius: 1.5,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        cursor: 'pointer',
                        backgroundColor:
                          currentSlug === 'specialRole4'
                            ? theme.palette.secondary.main
                            : 'transparent',
                        '&:hover': {
                          backgroundColor:
                            currentSlug === 'specialRole4'
                              ? theme.palette.secondary.main
                              : theme.palette.action.hover,
                        },
                      }}
                      onClick={() => {
                        navigate(`/chat`);
                        setOpen(false);
                      }}
                    >
                      <Iconify
                        icon="mdi:chat"
                        sx={{
                          color:
                            currentSlug === 'specialRole4' ? 'white' : theme.palette.primary.main,
                          mr: 0.5,
                        }}
                        width={24}
                      />
                      <Typography variant="body1" fontWeight="bold">
                        Chat
                      </Typography>
                    </Stack>
                    <Stack
                      key="consultations"
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        ml: 2,
                        padding: theme.spacing(2),
                        borderRadius: 1.5,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        cursor: 'pointer',
                        backgroundColor:
                          currentSlug === 'consultations'
                            ? theme.palette.primary.main
                            : 'transparent',
                        '&:hover': {
                          backgroundColor:
                            currentSlug === 'consultations'
                              ? theme.palette.primary.main
                              : theme.palette.action.hover,
                        },
                      }}
                      onClick={() => {
                        navigate(`/consultations`);
                        setOpen(false);
                      }}
                    >
                      <Iconify
                        icon="teenyicons:appointments-outline"
                        sx={{
                          color:
                            currentSlug === 'consultations' ? 'white' : theme.palette.primary.main,
                          mr: 0.5,
                        }}
                        width={24}
                      />
                      <Typography variant="body1" fontWeight="bold">
                        Consultations
                      </Typography>
                    </Stack>
                    <Stack
                      key="mail"
                      direction="row"
                      alignItems="center"
                      spacing={1}
                      sx={{
                        ml: 2,
                        padding: theme.spacing(2),
                        borderRadius: 1.5,
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        cursor: 'pointer',
                        backgroundColor:
                          currentSlug === 'mail' ? theme.palette.primary.main : 'transparent',
                        '&:hover': {
                          backgroundColor:
                            currentSlug === 'mail'
                              ? theme.palette.primary.main
                              : theme.palette.action.hover,
                        },
                      }}
                      onClick={() => {
                        navigate(`/mail`);
                        setOpen(false);
                      }}
                    >
                      <Iconify
                        icon="material-symbols:mail"
                        sx={{
                          color: currentSlug === 'mail' ? 'white' : theme.palette.primary.main,
                          mr: 0.5,
                        }}
                        width={24}
                      />
                      <Typography variant="body1" fontWeight="bold">
                        Mail
                      </Typography>
                    </Stack>
                  </>
                );
              }

              // Render categories for other roles
              return categories?.map((category, index) => (
                <Stack
                  key={category?.name}
                  direction="row"
                  alignItems="center"
                  spacing={1}
                  sx={{
                    ml: 2,
                    padding: theme.spacing(2),
                    borderRadius: 1.5,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                    cursor: 'pointer',
                    backgroundColor:
                      currentSlug === category?.slug ? theme.palette.primary.main : 'transparent',
                    '&:hover': {
                      backgroundColor:
                        currentSlug === category?.slug
                          ? theme.palette.primary.main
                          : theme.palette.action.hover,
                    },
                  }}
                  onClick={() => {
                    navigate(`/category/${category?.slug}`);
                    setOpen(false);
                  }}
                >
                  {category?.icon && (
                    <Iconify
                      icon={category.icon}
                      sx={{
                        color:
                          currentSlug === category?.slug ? 'white' : theme.palette.primary.main,
                        mr: 0.5,
                      }}
                      width={24}
                    />
                  )}
                  <Typography variant="body1" fontWeight="bold">
                    {category?.name}
                  </Typography>
                </Stack>
              ));
            })()}
          </Stack>
        </Scrollbar>

        {/* <Box sx={{ p: 2.5 }}>
          <SignOutButton onClose={handleCloseDrawer} />
        </Box> */}
      </Drawer>
    </>
  );
}
