export function support_email() {
  return 'soulfulpsychicsteam@gmail.com';
}

export function websiteUrl() {
  return 'https://soulfulpsychics.dotserviz.co/';
}

export function legal_email() {
  return 'legal@SoulfulPsychic.com';
}
